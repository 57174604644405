import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./assets/css/scss/style.css";
import LandUsers from "./components/land_user";
import DriverRegistration from "./components/Driver/driverRegistration";
import DriverLogin from "./components/Driver/driverLogin";
import DriverDashboard from "./components/Driver/driverDashboard";
import CreateStudent from "./components/Driver/CreateStudent";
import DriverProcess from "./components/Driver/driverProcess";
import FilterStudent from "./components/Driver/filterStudent";
import Payment from "./components/Driver/payment";
import Reports from "./components/Driver/reports";
import PageNotFound from "./components/404";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

function App() {
  function getCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookies = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookies.split(";");

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null; // Return null if the cookie is not found
  }

  const authToken = getCookie("authToken");

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              authToken ? <Navigate to="/driver/dashboard" /> : <LandUsers />
            }
          ></Route>
          <Route
            path="/driver/register"
            element={<DriverRegistration />}
          ></Route>
          <Route
            path="/login"
            element={
              authToken ? <Navigate to="/driver/dashboard" /> : <DriverLogin />
            }
          ></Route>
          {/* <Route path="/driver/auth" element={<DriverAuth />} /> */}

          <Route
            path="/driver/dashboard"
            element={authToken ? <DriverDashboard /> : <Navigate to="/" />}
          />
          <Route
            path="/students/create"
            element={authToken ? <CreateStudent /> : <Navigate to="/" />}
          ></Route>
          <Route
            path="/driver/process"
            element={authToken ? <DriverProcess /> : <Navigate to="/" />}
          ></Route>
          <Route
            path="/students/filter"
            element={authToken ? <FilterStudent /> : <Navigate to="/" />}
          ></Route>
          <Route
            path="/driver/payment"
            element={authToken ? <Payment /> : <Navigate to="/" />}
          ></Route>
          <Route
            path="/driver/reports"
            element={authToken ? <Reports /> : <Navigate to="/" />}
          ></Route>

          {/* 404 route */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
