import { TranslationOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { Modal, message, Input, Space, DatePicker, Select, QRCode } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from "dayjs";

import {
  createStudent,
  createParent,
  checkParentName,
  checkParentNIC,
  createSchool,
  checkSchool,
  checkLastStudent,
  createPaymentSatus,
} from "../services";

import {
  validatePhone,
  validateRequiredFields,
  validateNoneRequiredPhone,
  useParentValidation,
} from "../validation";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";

function CreateStudent() {
  // get inputs of students
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [bday, setBday] = useState(null);
  const [lastStudent, setLastStudent] = useState([]);

  // get inputs of parents
  const [parentName, setParentName] = useState("");
  const [parentNic, setParentNic] = useState("");
  const [parentPhone, setParentPhone] = useState("");
  const [parentPhone2, setParentPhone2] = useState("");
  const [parentPhone3, setParentPhone3] = useState("");

  const [parent, setParent] = useState("");
  const [searchParent, setSearchParent] = useState([]);

  const [modalOpen1, setModalOpen1] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);

  // get inputs of schools
  const [school, setSchool] = useState("");
  const [searchschool, setSearchSchool] = useState([]);
  const [schoolId, setSchoolId] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [schoolLocation, setSchoolLocation] = useState("");

  // school validation
  const [schoolNameError, setSchoolNameError] = useState("");
  const [schoolLocationError, setSchoolLocationError] = useState("");

  // student validation
  const [fNameError, setFNameError] = useState("");
  const [lNameError, setLNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [schoolError, setSchoolError] = useState("");
  const [age, setAge] = useState(null);

  // parent validation
  const [parentError, setParentError] = useState("");
  const [parentNameError, setParentNameError] = useState("");
  const [parentNicError, setParentNicError] = useState("");
  const [parentPhoneError, setParentPhoneError] = useState("");
  const [parentPhone2Error, setParentPhone2Error] = useState("");
  const [parentPhone3Error, setParentPhone3Error] = useState("");

  const fullName = firstName + " " + lastName;
  const dateFormat = "YYYY-MM-DD";
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const isParentRegistered = useParentValidation(parentNic);

  // fetch last row data in student table
  checkLastStudent()
    .then((res) => {
      setLastStudent(res.data[0].stu_id);
    })
    .catch((err) => console.log("School Not Found"));

  const selectedId = lastStudent + 1;

  const qrCodeRef = useRef(null);

  function closeModal() {
    setModalOpen1(false);
    setModalOpen2(false);
    setModalOpen3(false);
  }

  // add parent btn
  function addParentBtnClick() {
    setModalOpen1(true);
    setSchoolError("");
  }
  function addSchoolBtnClick() {
    setModalOpen2(true);
    setSchoolError("");
  }

  function handleBdayChange(date) {
    setBday(date);

    if (date) {
      const today = dayjs();
      const selectedDate = dayjs(date);
      const age = today.diff(selectedDate, "year");
      setAge(age);
    }
  }


  function handleParentCreate(event) {
    event.preventDefault();
    if (
      validateRequiredFields(parentNic) ||
      validateRequiredFields(parentName) ||
      validateRequiredFields(parentPhone)
    ) {
      message.error("All Fields Are Required");
      return;
    } else if (isParentRegistered) {
      message.error("Parent Already Registered ");
      return;
    }
    createParent({
      p_nic: parentNic,
      p_name: parentName,
      p_primary_phone: parentPhone,
      p_phone_2: parentPhone2,
      p_phone_3: parentPhone3,
    })
      .then((res) => {
        console.log(res);
        message.success("Parent Created Successfully");
        closeModal();
        setParentName("");
        setParentNic("");
        setParentPhone("");
        setParentPhone2("");
        setParentPhone3("");
        setParentPhoneError("");
        setParentPhone2Error("");
        setParentPhone3Error("");
        setParentNameError("");
        setParentNicError("");
      })
      .catch((err) => console.log(err));
  }

  function handleSubmit() {
    if (
      validateRequiredFields(firstName) ||
      validateRequiredFields(lastName) ||
      validateRequiredFields(address) ||
      validateRequiredFields(school)
    ) {
      {
        message.error("All fields are required");
        return;
      }
    } else {
      createStudent({
        firstName: firstName,
        lastName: lastName,
        fullName: fullName,
        address: address,
        birthday: bday,
        school: schoolName,
        parentNic: parentNic,
        schoolId: schoolId,
        stu_id: selectedId,
      })
        .then((res) => {
          console.log(res);
          setFirstName("");
          setLastName("");
          setAddress("");
          setBday("");
          setSchool("");
          setParentNic("");
          setSchoolId("");
        })
        .catch((err) => console.log(err));

      createPaymentSatus({
        stu_id: selectedId,
        status: 0,
      })
        .then((res) => {
          console.log(res);
          messageApi.open({
            key,
            type: "loading",
            content: "Creating Student",
          });
          setTimeout(() => {
            messageApi.open({
              key,
              type: "success",
              content: "Student Creation Successful",
              duration: 2,
            });
            setModalOpen3(true);
          }, 2000);
        })
        .catch((err) => console.log(err));
    }
  }

  function checkRegistration() {
    if (!parentNic) {
      setParentNicError("This field is required");
    } else if (isParentRegistered) {
      setParentNicError("Parent Already Registered!");
    } else {
      setParentNicError("");
    }
  }

  function handleSchoolCreate(event) {
    event.preventDefault();
    if (
      validateRequiredFields(schoolName) ||
      validateRequiredFields(schoolLocation)
    ) {
      message.error("All Fields Are Required");
      return;
    } else {
      createSchool({
        schoolName: schoolName,
        schoolLocation: schoolLocation,
      });
      message.success("School Creation Successful");
      setModalOpen2(false)
      console.log("modal colosed")
    }
  }

  // search parent by name
  useEffect(() => {
    checkParentName(parent)
      .then((res) => {
        setSearchParent(res.data);
      })
      .catch((err) => console.log("user Not Found"));
  }, [parent]);

  const onChange = (value) => {
    setParentNic(value);
  };
  const onSearch = (value) => {
    setParent(value);
  };

  // serach school by name
  useEffect(() => {
    checkSchool(school)
      .then((res) => {
        setSearchSchool(res.data);
      })
      .catch((err) => console.log("School Not Found"));
  }, [school]);

  function schoolOnChange(value) {
    setSchoolId(value);
  }
  function SchoolOnSearch(value) {
    setSchool(value);
  }

  // download QR Code
  const downloadQr = () => {
    const qrCodeContainer = qrCodeRef.current;
    html2canvas(qrCodeContainer, { backgroundColor: "#ffffff" }).then(
      (canvas) => {
        const url = canvas.toDataURL("image/jpeg");
        const a = document.createElement("a");
        a.download = "QRCode.jpg";
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    );
    navigate("/driver/dashboard");
    message.success("Download Successful");
  };

  // cancel btn
  function cancelQrBtn() {
    navigate("/driver/dashboard");
  }

  return (
    <div className="createStudent_main">
      <div className="nav_Container">
        <div className="nav">
          <ArrowLeftOutlined onClick={() => navigate("/driver/dashboard")} />
          <span>Add Student</span>
          <TranslationOutlined />
        </div>
      </div>
      <div className="top_svg">
        <svg
          width="205"
          height="174"
          viewBox="0 0 205 174"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_12)">
            <rect
              x="92.387"
              y="159.066"
              width="229.41"
              height="336.177"
              rx="59"
              transform="rotate(-111.405 92.387 159.066)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_12"
              x="0.107971"
              y="-197.765"
              width="431.819"
              height="371.383"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="9" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_12"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_12"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="bottom_left_svg">
        <svg
          width="273"
          height="543"
          viewBox="0 0 273 543"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_2)">
            <rect
              x="-83.8308"
              y="18"
              width="360"
              height="608"
              rx="59"
              transform="rotate(26.3822 -83.8308 18)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_2"
              x="-349.941"
              y="0.0591431"
              width="622.557"
              height="734.527"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="19" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_2"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_2"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="right_svg">
        <svg
          width="151"
          height="299"
          viewBox="0 0 151 299"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_433)">
            <rect
              x="18"
              y="211.12"
              width="360"
              height="452"
              rx="40"
              transform="rotate(-30.2061 18 211.12)"
              fill="#E9F0FE"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_433"
              x="0.686768"
              y="0.686798"
              width="591.153"
              height="624.375"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="9" dy="-3" />
              <feGaussianBlur stdDeviation="20.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_433"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_433"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="content_container">
        <div className="content">
          <div className="nameRow">
            <div className="nameCol__addStudent">
              <Input
                style={{
                  width: "100%",
                }}
                className={`${fNameError ? "is-invalid" : ""}`}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                onBlur={() => setFNameError(validateRequiredFields(firstName))}
                placeholder="First Name"
              />
              {fNameError && (
                <div className="invalid-feedback">{fNameError}</div>
              )}
            </div>

            <div className="nameCol__addStudent">
              <Input
                style={{
                  width: "100%",
                }}
                className={` ${lNameError ? "is-invalid" : ""}`}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                onBlur={() => setLNameError(validateRequiredFields(lastName))}
                placeholder="Last Name"
              />
              {lNameError && (
                <div className="invalid-feedback">{lNameError}</div>
              )}
            </div>
          </div>
          <div style={{ height: "70px", width: "100%" }}>
            <Input
              style={{
                width: "100%",
              }}
              className={`${addressError ? "is-invalid" : ""}`}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              onBlur={() => setAddressError(validateRequiredFields(address))}
              placeholder="Address"
            />
            {addressError && (
              <div className="invalid-feedback">{addressError}</div>
            )}
          </div>
          <Space
            align="center"
            style={{ height: "40px", width: "100%", marginBottom: "25px" }}
          >
            <DatePicker
              style={{ width: "100%" }}
              defaultValue={dayjs("2015/06/06", dateFormat)}
              format={dateFormat}
              value={bday}
              onChange={handleBdayChange}
            />

            {age && <div className="showAgeContainer">Age: {age} ?</div>}
          </Space>
          <div className="inputWithSideButton_row">
            <div className="selectParent_container">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Select a perant"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    label: "Parents",
                    options: searchParent.map((r) => ({
                      label: r.parent_name,
                      value: r.parent_nic,
                    })),
                  },
                ]}
              />
            </div>
            <button className="addMoreBtn" onClick={addParentBtnClick}>
              +
            </button>
          </div>
          <div className="inputWithSideButton_row">
            <div className="selectSchool_container">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Select a School"
                optionFilterProp="children"
                onChange={schoolOnChange}
                onSearch={SchoolOnSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={searchschool.map((r) => ({
                  label: `${r.school_name} ( ${r.school_location} )`, // Combine school name and location
                  value: r.school_id,
                }))}
              />
            </div>
            <button className="addMoreBtn" onClick={addSchoolBtnClick}>
              +
            </button>
          </div>
          <div className="submitButton__addStudent">
            <button
              onClick={handleSubmit}
              type={"submit"}
              className="primaryBtn_global"
            >
              Add Student
            </button>
          </div>
          {contextHolder}
          <Modal
            title={"Add Parent"}
            open={modalOpen1}
            onCancel={closeModal}
            footer={null}
            centered
          >
            <div className="mb-3">
              <input
                type="text"
                className={`form-control ${schoolError ? "is-invalid" : ""}`}
                placeholder="Full Name"
                value={parentName}
                onChange={(e) => setParentName(e.target.value)}
                onBlur={() =>
                  setSchoolError(validateRequiredFields(parentName))
                }
              />
              {parentNameError && (
                <div className="invalid-feedback">{parentNameError}</div>
              )}
            </div>
            <div className="mb-3">
              <input
                type="text"
                className={`form-control ${parentNicError ? "is-invalid" : ""}`}
                placeholder="National idenity Card Number"
                value={parentNic}
                onChange={(e) => setParentNic(e.target.value)}
                onBlur={checkRegistration}
              />
              {parentNicError && (
                <div className="invalid-feedback">{parentNicError}</div>
              )}
            </div>
            <div className="mb-3">
              <input
                type="text"
                className={`form-control ${
                  parentPhoneError ? "is-invalid" : ""
                }`}
                placeholder={"Phone number"}
                value={parentPhone}
                onChange={(e) => setParentPhone(e.target.value)}
                onBlur={() => {
                  setParentPhoneError(validatePhone(parentPhone));
                }}
              />
              {parentPhoneError && (
                <div className="invalid-feedback">{parentPhoneError}</div>
              )}
            </div>

            <div className="mb-3">
              <input
                type="text"
                className={`form-control ${
                  parentPhone2Error ? "is-invalid" : ""
                }`}
                placeholder={"Phone number 02"}
                value={parentPhone2}
                onChange={(e) => setParentPhone2(e.target.value)}
                onBlur={() => {
                  setParentPhone2Error(validateNoneRequiredPhone(parentPhone2));
                }}
              />
              {parentPhone2Error && (
                <div className="invalid-feedback">{parentPhone2Error}</div>
              )}
            </div>

            <div className="mb-3">
              <input
                type="text"
                className={`form-control ${
                  parentPhone3Error ? "is-invalid" : ""
                }`}
                placeholder={"Phone number 03"}
                value={parentPhone3}
                onChange={(e) => setParentPhone3(e.target.value)}
                onBlur={() => {
                  setParentPhone3Error(validateNoneRequiredPhone(parentPhone3));
                }}
              />
              {parentPhone3Error && (
                <div className="invalid-feedback">{parentPhone3Error}</div>
              )}
            </div>

            <Space align="center">
              <button
                onClick={handleParentCreate}
                className="primaryBtn_global"
              >
                Add Parent
              </button>
            </Space>
          </Modal>

          <Modal
            title={"Add School"}
            open={modalOpen2}
            onCancel={closeModal}
            footer={null}
            centered
          >
            <div className="mb-3">
              <input
                type="text"
                className={`form-control ${
                  schoolNameError ? "is-invalid" : ""
                }`}
                placeholder="School Name"
                value={schoolName}
                onChange={(e) => setSchoolName(e.target.value)}
                onBlur={() =>
                  setSchoolNameError(validateRequiredFields(schoolName))
                }
              />
              {schoolNameError && (
                <div className="invalid-feedback">{schoolNameError}</div>
              )}
            </div>
            <div className="mb-3">
              <input
                type="text"
                className={`form-control ${
                  schoolLocationError ? "is-invalid" : ""
                }`}
                placeholder="Location"
                value={schoolLocation}
                onChange={(e) => setSchoolLocation(e.target.value)}
                onBlur={() =>
                  setSchoolLocationError(validateRequiredFields(schoolLocation))
                }
              />
              {schoolLocationError && (
                <div className="invalid-feedback">{schoolLocationError}</div>
              )}
            </div>

            <Space align="center">
              <button
                onClick={handleSchoolCreate}
                className="primaryBtn_global"
              >
                Add school
              </button>
            </Space>
          </Modal>
          <Modal
            title={"Download QR Code"}
            open={modalOpen3}
            onCancel={closeModal}
            footer={null}
            centered
          >
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flexWrap: "wrap",
              }}
              ref={qrCodeRef}
              className="qrCode_container"
            >
              <QRCode
                size={256}
             //   style={{ height: "auto", maxWidth: "auto", width: "300" }}
                value={lastStudent}
                color="rgb(0, 0, 0)"
              />
              {lastStudent && (
                <div className="overlayBox">
                  <div className="overlayText">Student ID : {lastStudent}</div>
                </div>
              )}
            </div>
            <div className="qrModalBtn_container">
              <button onClick={downloadQr} className="primaryBtn_global">
                Download
              </button>
              <button
                className="primaryBtn_global skip_btn"
                onClick={cancelQrBtn}
              >
                Skip
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default CreateStudent;
