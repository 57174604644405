import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuOutlined, TranslationOutlined } from "@ant-design/icons";
import DriverPanel from "./driverPanel";
import { Button, Dropdown, Space, message } from "antd";
import DriverSideBar from "./driverSideBar";
import dayjs from "dayjs";
import { sendMsg, sendSMS } from "../services";

function DriverDashboard() {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  // open side bar
  function menuClick() {
    setShowMenu(true);
  }

  function closeMenu() {
    setShowMenu(false);
  }

  return (
    <section className="driverDashboard_main">
      {showMenu && <DriverSideBar data={showMenu} colseSideBar={closeMenu} />}
      <div className="nav_Container">
        <div className="nav">
          <MenuOutlined onClick={menuClick} />
          <span>WELCOME</span>
          <TranslationOutlined />
        </div>
      </div>
      <div className="top_svg">
        <svg
          width="205"
          height="174"
          viewBox="0 0 205 174"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_12)">
            <rect
              x="92.387"
              y="159.066"
              width="229.41"
              height="336.177"
              rx="59"
              transform="rotate(-111.405 92.387 159.066)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_12"
              x="0.107971"
              y="-197.765"
              width="431.819"
              height="371.383"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="9" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_12"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_12"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <DriverPanel />
      <div className="bottom_svg">
        <svg
          width="273"
          height="543"
          viewBox="0 0 273 543"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_2)">
            <rect
              x="-83.8308"
              y="18"
              width="360"
              height="608"
              rx="59"
              transform="rotate(26.3822 -83.8308 18)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_2"
              x="-349.941"
              y="0.0591431"
              width="622.557"
              height="734.527"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="19" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_2"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_2"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="content_container">
        <Space className="content">
          <Button
            className="contentBtn"
            onClick={() => navigate("/driver/process")}
          >
            Go
          </Button>
          <Button
            className="contentBtn"
            type="primary"
            onClick={() => navigate("/students/create")}
          >
            Add student
          </Button>
          <Button
            className="contentBtn"
            type="primary"
            onClick={() => navigate("/students/filter")}
          >
            student
          </Button>
        </Space>
      </div>
    </section>
  );
}

export default DriverDashboard;
