import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import { useNavigate } from "react-router-dom";

function DriverSideBarBefore({ data, colseSideBar }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      showDrawer();
    } else {
      setOpen(false);
    }
  }, [data]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    colseSideBar(false);
  };

  return (
    <div className="DriverSideBar_root">
      {/* <MenuOutlined className="menuBtn" onClick={showDrawer} /> */}
      <Drawer
        width={220}
        placement="left"
        open={open}
        onClose={onClose}
        closable={false}
        bodyStyle={{ padding: "0" }}
      >
        <ArrowLeftOutlined className="backBtn" onClick={onClose} />
          <div className="beforeSideBarPages">
            <ul>
              <li>Contact Us</li>
              <li>About Us</li>
            </ul>
          </div>
      </Drawer>
    </div>
  );
}

export default DriverSideBarBefore;
