import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined, UserAddOutlined } from "@ant-design/icons";
import { Drawer, Modal, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import safeKidsImg from "../../assets/img/safeKidsSystem.png";
import MrTechLogo from "../../assets/img/MrTechLogo.png";
import favic_icon from "../../assets/img/favic_icon.png";
import creadea_icon from "../../assets/img/creadea_icon.png";
import jwt_decode from 'jwt-decode';

function DriverSideBar({ data, colseSideBar }) {
  const [open, setOpen] = useState(false);
  const [driverName, setDriverName] = useState([]);
  const [driverNIC, setDriverNIC] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();


  //get cookies
  function getCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookies = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookies.split(";");

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  }

  //delete cookies
  function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }


  useEffect(() => {
    const token = getCookie("authToken");
    const decodedToken = jwt_decode(token);
    setDriverNIC(decodedToken.userId);
    setDriverName(decodedToken.fullname);
  }, []);

  useEffect(() => {
    if (data) {
      showDrawer();
    } else {
      setOpen(false);
    }
  }, [data]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    colseSideBar(false);
  };

  return (
    <div className="DriverSideBar_root">
      {/* <MenuOutlined className="menuBtn" onClick={showDrawer} /> */}
      <Drawer
        width={220}
        placement="left"
        open={open}
        onClose={onClose}
        closable={false}
        bodyStyle={{ padding: "0" }}
      >
        <ArrowLeftOutlined className="backBtn" onClick={onClose} />
        <div className="info">
          <span>{driverName}</span>
          <span>{driverNIC}</span>
        </div>
        <div className="content">
          <div className="pages">
            <ul>
              {/* <li>Current Trip Details</li> */}
              <li onClick={() => navigate("/driver/payment")}>Payment</li>
              <li onClick={() => navigate("/students/filter")}> student</li>
              <li onClick={() => navigate("/students/create")}>
                Add New Student
              </li>
              {/* <li onClick={() => navigate("/driver/reports")}>Reports</li> */}
            </ul>
          </div>
          <div className="about">
            <ul>
              <li>
                <a
                  href="https://creadea.lk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </a>
              </li>
              <li
                onClick={() => {
                  setOpen(false);
                  setModalOpen(true);
                }}
              >
                about Us
              </li>
            </ul>
          </div>
          <button
            className="logoutBtn"
            onClick={() => {
              deleteCookie('authToken');
              deleteCookie("Trip");
              window.location.reload();
            }}
          >
            Log Out
          </button>
        </div>
      </Drawer>
      <div className="about_popup">
        <Modal
          open={modalOpen}
          onCancel={() => {
            setModalOpen(false);
            setOpen(true);
          }}
          footer={null}
          centered

        >
          <div>
            <div className="headerImg">
              <img src={safeKidsImg} alt="safe kids" />
            </div>

            <div className="txt_container">
              <Space align="center">
                <span className="txt">Software Version: v1.0</span>
              </Space>
              <Space align="center">
                <span className="txt">
                  Legal ownership : Creadea Solutions{" "}
                </span>
              </Space>
              <Space align="center">
                <span className="txt">Developments : Creadea Solutions </span>
              </Space>
              <Space align="center">
                <span className="txt">
                  Project Status : production build v1.0{" "}
                </span>
              </Space>
            </div>
            <div className="logos">
              <img
                className="product_logo"
                width={75}
                src={favic_icon}
                alt="Safe Kids logo"
              />
              <a href="https://www.mrtech.lk/">
                <img
                  className="product_logo"
                  width={75}
                  src={MrTechLogo}
                  alt="MR TECH logo"
                />
              </a>
              <a href="https://creadea.lk/">
                <img
                  className="product_logo"
                  width={60}
                  style={{ alignItems: "center" }}
                  src={creadea_icon}
                  alt="Creadea logo"
                />
              </a>
            </div>

            <div className="bottom_text">
              <span className="txt">
                With all the respect to Mr.tech Engineering Solutions (Pvt) Ltd
              </span>
              <span className="txt txt2">
                Copyrights 2023 Creadea Solutions All rights reserved
              </span>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default DriverSideBar;
