import React, { useEffect, useRef, useState } from "react";
import {
  QRCode,
  Dropdown,
  Space,
  message,
  Tag,
  Popconfirm,
  Table,
  Modal,
  Skeleton,
} from "antd";
import { Html5QrcodeScanner } from "html5-qrcode";
import {
  checkLastMonthPaid,
  checkLastTrip,
  checkPayment,
  checkStudentId,
  createAttendance,
  createPayment,
  createPaymentSatus,
  createTrip,
  deletePendingStudent,
  fetchAllAttendance,
  fetchUnDropedScool,
  fetchUnDropedStudent,
  sendSMS,
  updateDropSchool,
  updateDropStudent,
  updateDropStudnet,
  updatePaymentstatus,
} from "../services";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeftOutlined,
  TranslationOutlined,
  DownOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import PDFObject from "pdfobject";
import "jspdf-autotable";
import Cookies from "js-cookie";
import safeKidsLogo from "../../assets/img/DailyReport.png";
import reportFooter from "../../assets/img/reportFooter.png";

function DriverProcess() {
  const [studentData, setStudentData] = useState([]);
  const [payment, setPayment] = useState([]);
  const [ride, setRide] = useState("Choose Trip");
  const [scanned, setScanned] = useState(false);
  const [scannerOpened, setScannerOpened] = useState(false);
  const [startBtn, setstartBtn] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("white");
  const [searchValue, setSearchValue] = useState("");
  const [boxShadow, setBoxShadow] = useState("");
  const [markBtnClick, setMarkBtnClick] = useState(false);
  const [markedSchool, setmarkedSchool] = useState([]);
  const [dropedSchool, setDropedSchool] = useState([]);
  const [markedStudent, setmarkedStudent] = useState([]);
  const [dropedStudent, setDropedStudent] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [paid, setPaid] = useState(false);
  const [lastMontPending, setLastMonthPending] = useState(false);
  const [pageIsRefreshed, setPageIsRefreshed] = useState(false);
  const [attendance, setAttendance] = useState();
  const [loading, setLoading] = useState(false);


  const [tripID, setTripID] = useState();

  const qrCodeRef = useRef(null);

  const navigate = useNavigate();

  const today = dayjs();

  useEffect(() => {
    setModalOpen(true);
  });

  function onPageLoad() {
    setPageIsRefreshed(true);
    console.log("Page loaded!");
  }
  window.addEventListener("load", onPageLoad);

  // get cookies 
  const token = Cookies.get("trip");
  useEffect(() => {
    if (token) {
      setRide(token);
    }
  }, [token, pageIsRefreshed]);

  // delete cookies
  function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  const items = [
    {
      label: <span onClick={() => setRide("Morning")}>Morning Ride</span>,
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: <span onClick={() => setRide("Evening")}>Evening Ride</span>,
      key: "1",
    },
  ];

  // sending msg
  const manageSMS = (content, phoneNumber) => {
    // sendSMS(content, phoneNumber)
    //   .then((response) => {
    //     console.log(response.data);
    //     message.success(`Message sent Successfully`);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  function closeModal() {
    setModalOpen(false);
    navigate("/driver/dashboard");
  }

  function startBtnClicked() {
    if (!startBtn) {
      if (ride === "Choose Trip") {
        message.warning(`Please secelct a trip`);
      } else {
        setstartBtn(true);
        setModalOpen(false);
        Cookies.set("trip", ride, { expires: 5 / 24 }); // store ride in cookies for 5 hours
        createTrip({
          status: ride,
          date: today,
        })
          .then((res) => {
            console.log(res);
            message.success(`${ride} Trip Start Successfully`);
          })
          .catch((err) => console.log(err));
      }
    }
  }

  // fetch student Data
  function fetchStudentData(result) {
    return new Promise((resolve, reject) => {
      setLoading(true);
      setTimeout(() => {
        checkStudentId(result)
          .then((res) => {
            if (res.data[0].status === 1) {
              setPaid(true);
            } else {
              setPaid(false);
            }
            // Checking last month paid or not
            checkLastMonthPaid(res.data[0].stu_id).then((res) => {
              if (res.data[0].stu_id === 1) {
                setLastMonthPending(true);
              } else {
                setLastMonthPending(false);
              }
            });

            // Resolve after all processing is done
            resolve(res.data);
          })
          .catch((err) => {
            console.log("Error fetching data:", err);
            reject(err);
          })
          .finally(() => {
            // Always set loading to false when done (inside finally)
            setLoading(false);
          });
      }, 500);
    });
  }

  //search by stu id input
  async function serachBtnClick(x) {
    setSearchValue("");
    scanBtn(false);
    setScanned(true);
    try {
      const result = await fetchStudentData(searchValue);
      setStudentData(result);
      setMarkBtnClick(false);
    } catch (error) {
      console.error(error);
      message.error(`User Not Found`);
    }
  }

  // sacning
  async function scanBtn(state) {
    setScannerOpened(state);
    
    if (state === true) {
      const intervalId = setInterval(() => {
        const scanner = new Html5QrcodeScanner("reader", {
          qrbox: {
            width: 250,
            height: 250,
          },
          fps: 10,
        });

        scanner.render(success, error);

        async function success(value) {
          scanner.clear();
          if (value) {
            try {
              const result = await fetchStudentData(value);
              setStudentData(result);
              setScanned(true);
              setMarkBtnClick(false);
            } catch (error) {
              console.error(error);
            }
            checkPayment(value)
              .then((res) => {
                setPayment(res.data);
              })
              .catch((err) => console.log("user not found"));

            setMarkBtnClick(false);
          }
        }

        function error(err) {
          // console.log("erooooor")
          setLoading(false);
        }
      }, 500);

      // Clear the interval after running once
      setTimeout(() => {
        clearInterval(intervalId);
      }, 500);
    } else {
      setStudentData("");
      setScanned(false);
      setLoading(false);
    }
  }

  // mark attendance
  useEffect(() => {
    checkLastTrip()
      .then((res) => {
        setTripID(res.data[0].trip_id);
      })
      .catch((err) => console.log("user not found"));
  }, [scanned]);

  async function markAttendance() {
    if (ride === "Morning" || ride === "Evening" || token) {
      await createAttendance({
        stu_id: studentData[0].stu_id,
        pickup: today,
        drop: 0,
        trip_id: tripID,
      });
      setMarkBtnClick(true);
      setStudentData("");
      scanBtn(true);
      setScanned(false);
      setLoading(false);
      if (ride === "Evening") {
        manageSMS(
          "We have picked up your child from school. Thank you!!",
          `${studentData[0].parent_phone_primary}`
        );
      }
      message.success(`Mark Attendance Student ID : ${studentData[0].stu_id}`);
    } else {
      message.error(`Please Select trip Before Mark Attendance`);
    }
  }

  // fetch data for drop table
  useEffect(() => {
    // fetchiong student data evening and morning (id, name, parent phone number)
    if (ride === "Evening" || ride === "Morning") {
      fetchUnDropedStudent(tripID).then((res) => {
        if (Array.isArray(res.data)) {
          const updatedMarkedStudent = [...markedStudent]; // Create a copy of the current markedStudent array

          res.data.forEach((student) => {
            // Check if the student with the same id is already in the array
            const existingStudent = updatedMarkedStudent.find(
              (s) => s.id === student.stu_id
            );

            if (!existingStudent) {
              // If not found, add the new student
              updatedMarkedStudent.push({
                id: student.stu_id,
                name: student.fullName,
                number: student.parent_phone_primary,
                school: student.school_name,
              });
            }
          });

          const uniqueSchoolNames = new Set(
            markedSchool.map((school) => school.school)
          );

          res.data.forEach((school) => {
            const schoolData = {
              school: school.school_name,
            };

            if (!uniqueSchoolNames.has(schoolData.school)) {
              uniqueSchoolNames.add(schoolData.school);

              setmarkedSchool((prevMarkedSchool) => [
                ...prevMarkedSchool,
                schoolData,
              ]);
            }
          });

          setmarkedStudent(updatedMarkedStudent);
        } else {
          console.log("Response data is not an array.");
        }
      });
    }
  }, [markBtnClick, scanned, pageIsRefreshed, tripID, loading]);

  //payment
  function payBtn() {
    if (!lastMontPending) {
      createPayment({
        stu_id: studentData[0].stu_id,
        amount: "3000",
        date: today,
      });
      updatePaymentstatus(studentData[0].stu_id, {
        status: 1,
      })
        .then((res) => {
          console.log(res);
          message.success(`Payment Successfull`);
          setPaid(true);
        })
        .catch((err) => console.log(err));
    } else {
      deletePendingStudent(studentData[0].stu_id)
        .then((res) => {
          console.log(res);
          message.success(`Last Month Payment Successfull`);
          setLastMonthPending(false);
        })
        .catch((err) => console.log(err));
    }
  }

  //unPaid
  function unPaid() {
    updatePaymentstatus(studentData[0].stu_id, {
      status: 0,
    })
      .then((res) => {
        console.log(res);
        message.success(`${studentData[0].stu_id} : UnPaid`);
        setPaid(false);
      })
      .catch((err) => console.log(err));
  }

  const downloadQRCode = () => {
    const qrCodeContainer = qrCodeRef.current;
    html2canvas(qrCodeContainer, { backgroundColor: "#ffffff" }).then(
      (canvas) => {
        const url = canvas.toDataURL("image/jpeg");
        const a = document.createElement("a");
        a.download = "QRCode.jpg";
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    );
  };

  // set result container shadow color
  useEffect(() => {
    if (studentData && studentData.length > 0) {
      if (paid) {
        setBoxShadow("rgb(88, 214, 141) 0px 0px 11px 2px");
      } else {
        setBoxShadow(" 0 0 10px #FB4400");
      }
    } else {
      setBoxShadow(" 0 0 10px rgba(0, 0, 0, 0.25)");
    }
  }, [studentData, paid]);

  // result element if student data have
  const readerElement = () => {
    if (studentData && studentData.length > 0) {
      return (
        <div className="result" id="reader">
          <div className="img_studata_Container">
            <div className="stu_data">
              <span className="row"> Name : {studentData[0].fullName}</span>
              <span className="row">school : {studentData[0].school_name}</span>
              <span className="row">
                Parent Name : {studentData[0].parent_name}{" "}
              </span>
              <span className="row">
                {" "}
                Contact No : {studentData[0].parent_phone_primary}{" "}
              </span>
              {paid ? (
                <span className="row">
                  Payment
                  <Space size={[0, 0]} wrap>
                    <Tag color="#87d068">Paid</Tag>
                  </Space>
                </span>
              ) : (
                <span className="row">
                  Payment
                  <Space size={[0, 0]} wrap>
                    <Tag color="#f50">Pending</Tag>{" "}
                  </Space>
                </span>
              )}
            </div>
            <div className="image_contaier">
              <img
                className="userImg"
                src="https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1690866437~exp=1690867037~hmac=88b6ff746ed8cb130219a571eac582fd6be5ec040ef3c1c99a00a16dee6df7b7"
                alt="User Image"
              />
            </div>
          </div>
          {lastMontPending ? (
            <span className="lastMonthPending row">last month pending</span>
          ) : (
            <span className="lastMonthPaid row">Last Month Paid</span>
          )}
          <div className="btn_container">
            {markedStudent.some((item) => item.id === studentData[0].stu_id) ? (
              <button
                className="markBtn"
                onClick={() =>
                  handleDropStudent({
                    id: studentData[0].stu_id,
                    name: studentData[0].fullName,
                  })
                }
              >
                drop
              </button>
            ) : (
              <button onClick={markAttendance} className="markBtn">
                Mark
              </button>
            )}
            {paid ? (
              <Popconfirm
                title="UnPaid Student"
                description="Are you sure to Unpaid this Student?"
                onConfirm={unPaid}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
                placement="topRight"
              >
                <button className="paidBtn">Unpaid</button>
              </Popconfirm>
            ) : (
              <button onClick={payBtn} className="pendingBtn">
                Pay
              </button>
            )}
          </div>
        </div>
      );
    }
  };

  const handleDropSchool = (record) => {
    // drop data from markedSchool
    if (!dropedSchool.includes(record.school)) {
      setDropedSchool(record.school);
      updateDropSchool(record.school, {
        drop: today,
        trip_id: tripID,
      })
        .then((res) => {
          console.log(res);
          message.success(`${record.school} Students are droped`);
        })
        .catch((err) => console.log(err));
    }
    const isAlreadyMarked = markedSchool.some(
      (item) => item.school === record.school
    );
    if (isAlreadyMarked) {
      // Remove the school from markedSchool and markedStudent
      const updatedMarkedSchool = markedSchool.filter(
        (item) => item.school !== record.school
      );
      const updatedMarkedStudent = markedStudent.filter(
        (item) => item.school !== record.school
      );
      setmarkedSchool(updatedMarkedSchool);
      setmarkedStudent(updatedMarkedStudent);
    } else {
      // Add the school to markedSchool
      setmarkedSchool((prevMarkedSchool) => [...prevMarkedSchool, record]);
    }
  };

  //drop student
  const handleDropStudent = (record) => {
    // Drop data for student
    if (!dropedStudent.includes(record.id)) {
      setDropedStudent((prevDropedStudent) => [
        ...prevDropedStudent,
        record.id,
      ]);
      updateDropStudent(record.id, {
        drop: today,
      })
        .then((res) => {
          console.log(res);
          message.success(`${record.id} Student droped`);
          console.log(record.parent_phone_primary);
          manageSMS(
            "your kid has been dropped safely at your home. Thank you!!",
            `${record.number}`
          );
        })
        .catch((err) => console.log(err));
    }

    const isAlreadyMarked = markedStudent.some((item) => item.id === record.id);
    if (isAlreadyMarked) {
      // Remove the student from markedStudent
      const updatedMarkedStudent = markedStudent.filter(
        (item) => item.id !== record.id
      );
      const updatedMarkedSchool = markedSchool.filter(
        (item) => item.id !== record.id
      );
      setmarkedStudent(updatedMarkedStudent);
      setmarkedSchool(updatedMarkedSchool);
    } else {
      // Add the student to markedStudent
      setmarkedStudent((prevMarkedStudent) => [...prevMarkedStudent, record]);
    }
  };

  // end btn click
  function endBtnClick() {
   // setModalOpen2(true);        after create pdf
    console.log(markedStudent);
    console.log(markedSchool);
    message.success(`${ride} Trip End Successfully`);
    deleteCookie("trip");
    setstartBtn(false);
    navigate("/driver/dashboard");
  }

  // genarating PDF
  function addHeader(doc) {
    doc.addImage(
      safeKidsLogo,
      "PNG",
      0,
      0,
      doc.internal.pageSize.getWidth(),
      40
    );
  }
  function addFooter(doc) {
    const footerWidth = doc.internal.pageSize.getWidth();
    const footerHeight = 20;

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const xPosition = pageWidth - footerWidth;
    const yPosition = pageHeight - footerHeight;
    doc.addImage(
      reportFooter,
      "PNG",
      xPosition,
      yPosition,
      footerWidth,
      footerHeight
    );
  }

  async function createpdf() {
    const doc = new jsPDF();
    const columns = ["Student Name", "School"];
    const data = [];

    await fetchAllAttendance(tripID)
      .then((res) => {
        setAttendance(res.data);
        if (Array.isArray(res.data) && res.data.length > 0) {
          for (let i = 0; i < Math.min(res.data.length, 100); i++) {
            const item = res.data[i];
            if (item.fullName && item.school_name) {
              data.push([`${item.fullName}`, `${item.school_name}`]);
            }
          }
        } else {
          console.error("Invalid data format in the response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 80, // Adjust the Y position for the table
    });

    if (ride === "Morning" || "Evening") {
      addHeader(doc);
      addFooter(doc);
      doc.save("dailyReport.pdf");
      return doc.output("datauristring");
    }
  }
  return (
    <section className="driverProcess_main">
      <div className="nav_container">
        <div className="nav">
          <ArrowLeftOutlined onClick={() => navigate("/driver/dashboard")} />
          <span>Attendance</span>
          <TranslationOutlined />
        </div>
      </div>
      <>
        {token ? (
          <div className="endBtn_container">
            <Popconfirm
              title="End Trip"
              description="Do You Want End Trip?"
              onConfirm={endBtnClick}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
              placement="topRight"
            >
              <button className="endBtn">End {ride} Trip</button>
            </Popconfirm>
          </div>
        ) : (
          <Modal
            title={"Start New Trip"}
            open={modalOpen}
            onCancel={closeModal}
            footer={null}
            centered
          >
            <div className="mb-3">
              <div className="dropdown_container">
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                >
                  <span onClick={(e) => e.preventDefault()}>
                    <Space>{ride}</Space>
                  </span>
                </Dropdown>
              </div>
            </div>
            <Space align="center">
              <button onClick={startBtnClicked} className="primaryBtn_global">
                Start
              </button>
            </Space>
          </Modal>
        )}
      </>
      <div className="search_input">
        <input
          type="text"
          className="ant-input"
          placeholder="Student Id"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button className="primaryBtn_global" onClick={serachBtnClick}>
          search
        </button>
      </div>
      <div className="QRScan_container">
        <div className="QR_scanner" style={{ backgroundColor, boxShadow }}>
          <div className="result_container">
            {studentData.length > 0 ? (
              readerElement()
            ) : (
              <>
                {scannerOpened ? (
                  <div className="reader" id="reader"></div>
                ) : (
                  <>
                    {loading === true ? (
                      <Skeleton
                        style={{ padding: 20 }}
                        avatar
                        paragraph={{
                          rows: 7,
                        }}
                        active={true}
                      />
                    ) : (
                      <img
                        className="QrImg"
                        src="https://img.freepik.com/free-vector/qr-code-scanning-concept-with-characters_23-2148629214.jpg?t=st=1690865773~exp=1690866373~hmac=482e79b7b5eb3c5279e575137d0459eb016ae37665e95c285ff10d9f70310099"
                        alt="QR Code"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <div className="scanQrBtn">
          {!scannerOpened ? (
            <button
              className={scanned ? "CancelBtn" : "primaryBtn_global"}
              onClick={() => {
                if (scanned) {
                  setStudentData("");
                  scanBtn(false);
                } else {
                  scanBtn(true);
                }
              }}
            >
              {scanned ? "Cancel" : "Sacan Qr Code"}
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="table">
        {ride === "Morning" ? (
          <div>
            <Table
              columns={[
                {
                  title: "School",
                  dataIndex: "school",
                },
                {
                  title: "Drop",
                  dataIndex: "drop",
                  key: "operation",
                  render: (_, record) => (
                    <Space size={[0, 8]} wrap>
                      <Tag
                        color="#f50"
                        onClick={() => handleDropSchool(record)}
                      >
                        Drop
                      </Tag>
                    </Space>
                  ),
                },
              ]}
              size="middle"
              dataSource={markedSchool}
            />
          </div>
        ) : (
          <Table
            columns={[
              {
                title: "Student name",
                dataIndex: "name",
              },
              {
                title: "Drop",
                dataIndex: "drop",
                key: "operation",
                render: (_, record) => (
                  <Space size={[0, 8]} wrap>
                    <Tag color="#f50" onClick={() => handleDropStudent(record)}>
                      Drop
                    </Tag>
                  </Space>
                ),
              },
            ]}
            dataSource={markedStudent}
            pagination={{
              pageSize: 15,
            }}
            size="middle"
          />
        )}
      </div>
      <Modal
        title={"Student Details"}
        open={modalOpen2}
        onCancel={() => {
          setModalOpen2(false);
          message.success(`${ride} Trip End Successfully`);
          deleteCookie("Trip");
          setstartBtn(false);
          navigate("/driver/dashboard");
        }}
        footer={null}
        centered
      >
        {/* <div>{attendance ? <span>All Attendance : {attendance.length}</span>: <span></span>}</div> */}
        <Space align="center">
          <button
            onClick={() => {
              setModalOpen2(false);
              message.success(`${ride} Trip End Successfully`);
              deleteCookie("trip");
              setstartBtn(false);
              navigate("/driver/dashboard");
            }}
            className="primaryBtn_global"
          >
            cancel
          </button>
          <button
            onClick={() => {
              createpdf();
              message.success(`${ride} Trip End Successfully`);
              deleteCookie("trip");
              setstartBtn(false);
              navigate("/driver/dashboard");
            }}
            className="primaryBtn_global"
          >
            download
          </button>
        </Space>
      </Modal>

      {/* <div>
        <div ref={qrCodeRef}>
          <QRCode
            value={qrCode}
            style={{
              marginBottom: 16,
            }}
          />
          <p>Student Id : {qrCode}</p>
        </div>
        <button onClick={downloadQRCode} className="primaryBtn_global">
          Download
        </button>
        <input type="text" onChange={(e) => setQrCode(e.target.value)} />
      </div> */}

      <div className="top_svg">
        <svg
          width="205"
          height="174"
          viewBox="0 0 205 174"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_12)">
            <rect
              x="92.387"
              y="159.066"
              width="229.41"
              height="336.177"
              rx="59"
              transform="rotate(-111.405 92.387 159.066)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_12"
              x="0.107971"
              y="-197.765"
              width="431.819"
              height="371.383"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="9" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_12"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_12"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="bottom_left_svg">
        <svg
          width="273"
          height="543"
          viewBox="0 0 273 543"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_2)">
            <rect
              x="-83.8308"
              y="18"
              width="360"
              height="608"
              rx="59"
              transform="rotate(26.3822 -83.8308 18)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_2"
              x="-349.941"
              y="0.0591431"
              width="622.557"
              height="734.527"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="19" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_2"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_2"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="right_svg">
        <svg
          width="151"
          height="299"
          viewBox="0 0 151 299"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_433)">
            <rect
              x="18"
              y="211.12"
              width="360"
              height="452"
              rx="40"
              transform="rotate(-30.2061 18 211.12)"
              fill="#E9F0FE"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_433"
              x="0.686768"
              y="0.686798"
              width="591.153"
              height="624.375"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="9" dy="-3" />
              <feGaussianBlur stdDeviation="20.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_433"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_433"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </section>
  );
}

export default DriverProcess;

// styles to id on qr
// margin: 0 auto;
// width: fit-content;
// backdrop-filter: blur(1px);
// padding: 5px 65px;
// border-radius: 30px;
// border: 1px solid #ede3e3;

// previous code for qr
/* <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 64,
            width: "100%",
          }}
          id="qrCode_container"
        >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "auto", width: "300" }}
            value={qrCode}
            icon="https://tse3.mm.bing.net/th?id=OIP.F71gEWxKUIJFI6cCea_EXwHaFW&pid=Api&P=0&w=300&h=300.jpg"
          />

          <button onClick={downloadQr} className="primaryBtn_global">
            Download
          </button>
        </div> */
