import React, { useEffect, useState } from "react";
import { MenuOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Select, Button, Space, Popover, Input, message, Alert } from "antd";
import { fetchUserTypes, checkDriverNIC } from "./services";
import DriverSideBarBefore from "./Driver/driverSideBarBefore";

function LandUsers() {
  const [role, setRole] = useState([]);
  const [roleValue, setRoleValue] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [driverNotFound, setDriverNotFound] = useState(false);

  const [drivers, setDrivers] = useState("");
  const [showMenu, setShowMenu] = useState(false);

  const navigate = useNavigate();

  const handleUserChange = (value) => setRoleValue(value);
  const handleHoverChange = (open) => setHovered(open);
  const hoverContent = (
    <div>Sinhala language update is under constructions</div>
  );

  useEffect(() => getUserTypes(), []);

  function getUserTypes() {
    fetchUserTypes()
      .then((res) => setRole(res.data))
      .catch((err) => console.error(err));
  }

  const handleNextClick = () => {
    if (roleValue != null) {
      if (roleValue === 1) {
        const getDriverNic = document.getElementById("driverNicInput").value;
        if (getDriverNic) {
          checkDriverNIC(getDriverNic)
            .then((res) => {
              setDrivers(res.data);
            })
            .catch((err) => console.log(err));
        } else {
          message.error("Driver NIC input not found").then((r) => "FALSE");
        }
      } else {
        message
          .error("This user type is Under Constructions")
          .then((r) => "FALSE");
      }
    } else {
      message.error("User types is required").then((r) => "FALSE");
    }
  };

  useEffect(() => {
    if (drivers) {
      const getDriverNic = document.getElementById("driverNicInput").value;
      drivers.length === 1
        ? navigate("/login", { state: getDriverNic })
        : setDriverNotFound(true);
    }
  }, [drivers, navigate]);

  // open side bar
  function menuClick() {
    setShowMenu(true);
  }

  function closeMenu() {
    setShowMenu(false);
  }

  let slideIndex = 0; // Initialize slideIndex with 0

  function showSlides() {
    const slides = document.getElementsByClassName("fade");

    if (slides.length === 0) {
      console.error("No slides found with class 'fade'");
      return;
    }

    let i;

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slideIndex++;

    if (slideIndex > slides.length) {
      slideIndex = 1;
    }

    slides[slideIndex - 1].style.display = "block";
    setTimeout(showSlides, 4000);
  }

  window.onload = function () {
    showSlides();
  };

  return (
    <div className="landUsers_Root" id="landRoot">
      {showMenu && (
        <DriverSideBarBefore data={showMenu} colseSideBar={closeMenu} />
      )}
      {driverNotFound && (
        <Space className="errorMsgDriverNotFound">
          <Alert
            message="Driver Not Found"
            showIcon
            description="Enter Valid Driver NIC or Contact Creadea Customer Support right away"
            type="error"
            action={
              <Button size="small" info>
                Contact
              </Button>
            }
          />
        </Space>
      )}
      <div className="nav_Container">
        <div className="nav">
          <MenuOutlined onClick={menuClick} />
          <span>WELCOME</span>
          <QuestionCircleOutlined />
        </div>
      </div>
      <div className="top_svg">
        <svg
          width="205"
          height="174"
          viewBox="0 0 205 174"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_12)">
            <rect
              x="92.387"
              y="159.066"
              width="229.41"
              height="336.177"
              rx="59"
              transform="rotate(-111.405 92.387 159.066)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_12"
              x="0.107971"
              y="-197.765"
              width="431.819"
              height="371.383"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="9" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_12"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_12"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="sub_content">
        <div className="fade">
          <img src="https://safekids.creadea.lk/bannerlandingpage_1.png" />
        </div>
        <div className="fade">
          <img src="https://safekids.creadea.lk/safekidsbanner.png" />
        </div>
      </div>
      <div className="bottom_svg">
        <svg
          width="273"
          height="543"
          viewBox="0 0 273 543"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_2)">
            <rect
              x="-83.8308"
              y="18"
              width="360"
              height="608"
              rx="59"
              transform="rotate(26.3822 -83.8308 18)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_2"
              x="-349.941"
              y="0.0591431"
              width="622.557"
              height="734.527"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="19" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_2"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_2"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="content_container">
        <div className="content">
          <div className="selectWrapper">
            <Popover
              style={{
                width: 500,
              }}
              content={hoverContent}
              placement="top"
              title="Reminder"
              color="#E0FCFF"
              trigger="hover"
              open={hovered}
              onOpenChange={handleHoverChange}
            >
              <Select
                defaultValue="English"
                style={{
                  width: 250,
                  margin: "10px 0",
                  background: "rgba(0, 0, 0, 0)",
                  color: "#fff",
                  borderRadius: "62px",
                }}
                options={[
                  {
                    label: "Languages",
                    options: [
                      // {
                      //   label: "Sinhala",
                      //   value: "sinhala",
                      // },
                      {
                        label: "English",
                        value: "english",
                      },
                    ],
                  },
                ]}
              />
            </Popover>
          </div>
          {Array.isArray(role) && role.length > 0 ? (
            <div className="selectWrapper">
              <Select
                defaultValue="User Type"
                style={{
                  width: 250,
                  margin: "10px 0",
                  background: "rgba(0, 0, 0, 0)",
                  color: "#fff",
                  borderRadius: "62px",
                }}
                onChange={handleUserChange}
                options={[
                  {
                    label: "Users",
                    options: role.map((r) => ({
                      label: r.user_type,
                      value: r.user_type_id,
                    })),
                  },
                ]}
              />

              {roleValue === 1 ? (
                <div className="selectWrapper">
                  <Input
                    showCount
                    id="driverNicInput"
                    maxLength={20}
                    placeholder="Driver NIC"
                    style={{
                      width: 250,
                      margin: "10px 0",
                      border: "1.5px solid rgba(5, 53, 69, 0.90)",
                      borderRadius: "62px",
                    }}
                  />
                </div>
              ) : (
                <div></div>
              )}

              <Space wrap>
                <button className="nextBtn" onClick={handleNextClick}>
                  Next
                </button>
              </Space>
            </div>
          ) : (
            <p>
              Loading user types
              <span className="loading-dots">.</span>
              <span className="loading-dots">.</span>
              <span className="loading-dots">.</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default LandUsers;
