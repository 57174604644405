import React, { useEffect, useState } from "react";
import { Select, Space, Table, Tag, message, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, TranslationOutlined } from "@ant-design/icons";
import {
  createPending,
  fetchStudentsCount,
  filterPaidStudent,
  lastMonthUnPaidStudent,
  resetPaymentStatus,
} from "../services";
import dayjs from "dayjs";

function Payment() {
  const [selectValue, setselectValue] = useState(1);
  const [studentData, setStudentData] = useState([]);
  const [studentCount, setStudentCount] = useState();

  const navigate = useNavigate();
  
  const today = dayjs();

  useEffect(() => {
    fetchStudentsCount()
    .then((res) => {
      setStudentCount(res.data[0]["COUNT(*)"]);
    })
    .catch((err) => console.log(err));
  }, [])

  const openNotification = () => {
    notification.info({
      message: 'Cant Reset This month',
      description: 'You can reset payment only first five days of month. if you wanna reset payment now please contact admin',
      placement: 'topRight',
    });
  };

  const resetBtnClick = () => {
    if (today.format("D") < 5) {
      createPending({
        month: today.format("M") - 1,
      }).then((res) => {
        console.log(res);
        resetPaymentStatus({
          month: today.format("M"),
        })
          .then((res) => {
            console.log(res);
            message.success(`Reset ${today.format("MMM")} Month`);
          })
          .catch((err) => console.log(err));
      });
    } else{
      openNotification();
    }
  };

  const selectChange = (value) => {
    if (value.value === "paid") {
      setselectValue(1);
    } else if (value.value === "UnPaid") {
      setselectValue(0);
    } else if (value.value === "L_UnPaid") {
      setselectValue(2);
    } else {
      setselectValue(null);
    }
  };

  useEffect(() => {
    if (selectValue === 1) {
      filterPaidStudent(selectValue)
        .then((res) => {
          setStudentData(res.data);
        })
        .catch((err) => console.log("Student Not Found"));
    } else if (selectValue === 0) {
      filterPaidStudent(selectValue)
        .then((res) => {
          setStudentData(res.data);
        })
        .catch((err) => console.log("Student Not Found"));
    } else if (selectValue === 2) {
      lastMonthUnPaidStudent()
        .then((res) => {
          setStudentData(res.data);
        })
        .catch((err) => console.log("Student Not Found"));
    }
  }, [selectValue]);

  const payment = (value) => {};

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "School",
      dataIndex: "School",
    },
    {
      title: "Payment",
      dataIndex: "Payment",
    },
  ];

  const data = [];

  for (let i = 0; i < (studentCount + 2); i++) {
    if (studentData[i]) {
      data.push({
        key: i,
        name: `${studentData[i].fullName}`,
        School: `${studentData[i].school_name}`,
        Payment:
          studentData[i].status === 1 ? (
            <Space size={[0, 8]} wrap>
              <Tag color="#87d068"> Paid </Tag>
            </Space>
          ) : (
            <Space size={[0, 8]} wrap>
              <Tag color="#f50" onClick={payment(studentData[i])}>
                Pending
              </Tag>
            </Space>
          ),
      });
    }
  }

  return (
    <div className="payment_root">
      <div className="nav_Container">
        <div className="nav">
          <ArrowLeftOutlined onClick={() => navigate("/driver/dashboard")} />
          <span>Payment</span>
          <TranslationOutlined />
        </div>
      </div>
      <div className="reset_container">
        <div className="reser_text">
          <span>Reset Monthly Payment</span>
        </div>
        <div className="reset_btn">
          <button className="primaryBtn_global" onClick={resetBtnClick}>
            Reset
          </button>
        </div>
      </div>
      <div className="table_container">
        <div className="select">
          <Select
            labelInValue
            defaultValue={{
              label: "This Month paid",
            }}
            style={{
              width: 200,
            }}
            onChange={selectChange}
            options={[
              {
                value: "UnPaid",
                label: "This Month UnPaid",
              },
              {
                value: "paid",
                label: "This Month paid",
              },
              {
                label: "Last Month",
                options: [
                  {
                    label: "Unpaid",
                    value: "L_UnPaid",
                  },
                ],
              },
            ]}
          />
        </div>
        <div className="table">
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
            }}
            // scroll={{
            //   y: 270,
            // }}
            size="middle"
          />
        </div>
      </div>
      <div className="top_svg">
        <svg
          width="205"
          height="174"
          viewBox="0 0 205 174"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_12)">
            <rect
              x="92.387"
              y="159.066"
              width="229.41"
              height="336.177"
              rx="59"
              transform="rotate(-111.405 92.387 159.066)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_12"
              x="0.107971"
              y="-197.765"
              width="431.819"
              height="371.383"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="9" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_12"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_12"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="bottom_svg">
        <svg
          width="273"
          height="543"
          viewBox="0 0 273 543"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_2)">
            <rect
              x="-83.8308"
              y="18"
              width="360"
              height="608"
              rx="59"
              transform="rotate(26.3822 -83.8308 18)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_2"
              x="-349.941"
              y="0.0591431"
              width="622.557"
              height="734.527"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="19" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_2"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_2"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
}

export default Payment;
