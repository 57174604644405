import React, { useEffect, useState } from "react";
import {
  fetchStudentsCount,
  filterAllStudent,
  filterOneStudent,
  filterStudent,
} from "../services";
import { Modal, Space, Table, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { TranslationOutlined, ArrowLeftOutlined } from "@ant-design/icons";

function FilterStudent() {
  const navigate = useNavigate();

  const [studentData, setStudentData] = useState([]);
  const [OneStudentData, setOneStudentData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [studentCount, setStudentCount] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  if (searchValue) {
    filterStudent(searchValue)
      .then((res) => {
        setStudentData(res.data);
      })
      .catch((err) => console.log("Student Not Found"));
  }

  useEffect(() => {
    filterAllStudent()
      .then((res) => {
        setStudentData(res.data);
      })
      .catch((err) => console.log("Student Not Found"));

    fetchStudentsCount()
      .then((res) => {
        setStudentCount(res.data[0]["COUNT(*)"]);
      })
      .catch((err) => console.log(err));
  }, []);

  // ant desing table
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "School",
      dataIndex: "School",
    },
    {
      title: "Payment",
      dataIndex: "Payment",
    },
  ];

  const data = [];

  for (let i = 0; i < studentCount + 2; i++) {
    if (studentData[i]) {
      data.push({
        key: i,
        id: `${studentData[i].stu_id}`,
        name: `${studentData[i].fullName}`,
        School: `${studentData[i].school_name}`,
        Payment:
          studentData[i].status === 1 ? (
            <Space size={[0, 8]} wrap>
              <Tag color="#87d068"> Paid </Tag>
            </Space>
          ) : (
            <Space size={[0, 8]} wrap>
              <Tag color="#f50">Pending</Tag>
            </Space>
          ),
      });
    }
  }

  const handleRowClick = (record) => {
    setModalOpen(true);
    filterOneStudent(record.id)
      .then((res) => {
        setOneStudentData(res.data);
        console.log(res.data);
      })
      .catch((err) => console.log("Student Not Found"));
  };

  return (
    <div className="filterStudent_root">
      <div className="nav_Container">
        <div className="nav">
          <ArrowLeftOutlined onClick={() => navigate("/driver/dashboard")} />
          <span>Student Status</span>
          <TranslationOutlined />
        </div>
      </div>
      <div className="topSelect_container">
        <div className="input">
          <input
            type="text"
            className="ant-input"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      <div className="table_container">
        <div className="topic_container">
          <span className="custom_col"></span>
          <span className="topic custom_col">Students</span>
          <div className="custom_col">
            <button className="topic" onClick={() => {navigate("/students/create")}}>+</button>
          </div>
        </div>

        <div className="table">
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 15,
            }}
            size="small"
            onRow={(record) => ({
              onClick: () => {
                handleRowClick(record);
              },
            })}
          />
        </div>

        <Modal
          title={"Student Details"}
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
          footer={null}
          centered
        >
          {OneStudentData.length > 0 ? (
            <div className="stu_data">
              <span className="row">
                Student Id : {OneStudentData[0].stu_id}
              </span>
              <span className="row">
                Student Name : {OneStudentData[0].fullName}
              </span>
              <span className="row">
                School : {OneStudentData[0].school_name}
              </span>
              <span className="row">
                Parent Name : {OneStudentData[0].parent_name}
              </span>
              <span className="row">
                Parent NIC : {OneStudentData[0].parent_nic}
              </span>
              <span className="row">
                Contact Number : {OneStudentData[0].parent_phone_primary}
              </span>
            </div>
          ) : (
            <div></div>
          )}
          <Space align="center">
            <button
              onClick={() => setModalOpen(false)}
              className="primaryBtn_global"
            >
              cancel
            </button>
          </Space>
        </Modal>
      </div>
      <div className="top_svg">
        <svg
          width="205"
          height="174"
          viewBox="0 0 205 174"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_12)">
            <rect
              x="92.387"
              y="159.066"
              width="229.41"
              height="336.177"
              rx="59"
              transform="rotate(-111.405 92.387 159.066)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_12"
              x="0.107971"
              y="-197.765"
              width="431.819"
              height="371.383"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="9" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_12"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_12"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="bottom_svg">
        <svg
          width="273"
          height="543"
          viewBox="0 0 273 543"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_2)">
            <rect
              x="-83.8308"
              y="18"
              width="360"
              height="608"
              rx="59"
              transform="rotate(26.3822 -83.8308 18)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_2"
              x="-349.941"
              y="0.0591431"
              width="622.557"
              height="734.527"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="19" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_2"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_2"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
}

export default FilterStudent;
