import React, {useState} from "react";
import {Alert, Button, Input, Space, message} from "antd";
import {ArrowLeftOutlined, TranslationOutlined} from "@ant-design/icons";
import {useNavigate, useLocation} from "react-router-dom";
import {
    validatePhone,
    validateRequiredFields,
    useDriverValidation,
} from "../validation";
import {createDriver} from "../services";

function DriverRegistration() {
    const location = useLocation();
    const getDriverNic = location.state;
    // button loading animation
    const [loadings, setLoadings] = useState([]);
    const navigate = useNavigate();

    // get inputs
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [driverNic, setDriverNic] = useState("");
    const fullName = firstName + " " + lastName;

    // validations
    const [showError, setShowError] = useState(false);
    const [fNameError, setFNameError] = useState("");
    const [lNameError, setLNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [nicError, setNicError] = useState("");

    // Check if driver NIC is registered
    const isDriverRegistered = useDriverValidation(driverNic);

    function loadingOnSubmit(index) {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 1200);
    }

    function onSubmitClicked(event, index) {
        event.preventDefault();
        if (!fullName && !phone && !driverNic) {
            setShowError(true);
            return;
        }

        // input validations
        const firstNameValidationResult = validateRequiredFields(firstName);
        const lastNameValidationResult = validateRequiredFields(lastName);
        const phoneValidationResult = validatePhone(phone);

        if (
            firstNameValidationResult ||
            lastNameValidationResult ||
            phoneValidationResult

        ) {
            setFNameError(firstNameValidationResult);
            setLNameError(lastNameValidationResult);
            setPhoneError(phoneValidationResult);
            return;
        }

        createDriver({
            firstName: firstName,
            lastName: lastName,
            fullName: fullName,
            phone: phone,
            driverNic: getDriverNic,
        })
            .then((res) => {
                console.log(res);
                loadingOnSubmit(index);
                message.success("Registration successfull");
                navigate("/driver/dashboard");
            })
            .catch((err) => console.log(err));
    }

    // function checkRegistration() {
    //     if (!driverNic) {
    //         setNicError("This field is required");
    //     } else if (isDriverRegistered) {
    //         setNicError("Driver Already Registered! Please Login");
    //     }
    // }

    return (
        <div className="driverRegistration_root">
            <div className="nav_container">
                <div className="nav">
                    <ArrowLeftOutlined onClick={() => navigate("/")}/>
                    <span>WELCOME</span>
                    <TranslationOutlined/>
                </div>
            </div>
            <div className="top_svg">
                <svg
                    width="205"
                    height="174"
                    viewBox="0 0 205 174"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g filter="url(#filter0_d_160_12)">
                        <rect
                            x="92.387"
                            y="159.066"
                            width="229.41"
                            height="336.177"
                            rx="59"
                            transform="rotate(-111.405 92.387 159.066)"
                            fill="#63A8B8"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_160_12"
                            x="0.107971"
                            y="-197.765"
                            width="431.819"
                            height="371.383"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dx="9" dy="-3"/>
                            <feGaussianBlur stdDeviation="17.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_160_12"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_160_12"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </svg>
            </div>
            <div className="sub_content">
                <span></span>
            </div>
            <div className="bottom_svg">
                <svg
                    width="273"
                    height="543"
                    viewBox="0 0 273 543"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g filter="url(#filter0_d_160_2)">
                        <rect
                            x="-83.8308"
                            y="18"
                            width="360"
                            height="608"
                            rx="59"
                            transform="rotate(26.3822 -83.8308 18)"
                            fill="#63A8B8"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_160_2"
                            x="-349.941"
                            y="0.0591431"
                            width="622.557"
                            height="734.527"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dx="19" dy="-3"/>
                            <feGaussianBlur stdDeviation="17.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_160_2"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_160_2"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </svg>
            </div>
            <div className="content_container">
                <div className="formContainer">
                    {showError && (
                        <Alert
                            message="Please fill in all the fields"
                            type="error"
                            showIcon
                            className="mb-3"
                        />
                    )}
                    <div className="nameRow">
                        <div style={{height: "60px", width: "49%"}}>
                            <Input
                                style={{
                                    width: "100%",

                                }}
                                className={`form-input ${fNameError ? "is-invalid" : ""}`}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                onBlur={() => setFNameError(validateRequiredFields(firstName))}
                                placeholder="First Name"
                            />
                            {fNameError && (
                                <div className="invalid-feedback">{fNameError}</div>
                            )}
                        </div>

                        <div style={{height: "60px", width: "49%"}}>
                            <Input
                                style={{
                                    width: "100%",
                                }}
                                className={`form-input ${lNameError ? "is-invalid" : ""}`}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                onBlur={() => setLNameError(validateRequiredFields(lastName))}
                                placeholder="Last Name"
                            />
                            {lNameError && (
                                <div className="invalid-feedback">{lNameError}</div>
                            )}
                        </div>
                    </div>

                    <div style={{
                        height: "60px",
                        margin: "0px 0px 10px 0px",
                    }}>
                        <Input
                            className={`form-input ${phoneError ? "is-invalid" : ""}`}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            onBlur={() => setPhoneError(validatePhone(phone))}
                            placeholder="Phone"
                        />
                        {phoneError && <div className="invalid-feedback">{phoneError}</div>}
                    </div>

                    <div style={{
                        height: "60px",
                        margin: "0px 0px 0px 0px",
                    }}>
                        <Input
                            className={`form-input ${nicError ? "is-invalid" : ""}`}
                            value={getDriverNic}
                            onChange={(e) => setDriverNic(e.target.value)}
                            placeholder="Driver NIC"
                            disabled
                        />
                        {nicError && <div className="invalid-feedback">{nicError}</div>}
                    </div>

                    <Space className="driverRegBtn_container" wrap>
                        <Button
                            className="regBtn"
                            type="primary"
                            loading={loadings[1]}
                            onClick={(event) => onSubmitClicked(event, 1)}
                        >
                            Register
                        </Button>
                        {isDriverRegistered && (
                            <Button
                                className="logBtn"
                                type="primary"
                                loading={loadings[2]}
                                onClick={() => navigate("/login")}
                            >
                                Login
                            </Button>
                        )}
                    </Space>
                </div>
            </div>
        </div>
    );
}

export default DriverRegistration;
