import React, { useEffect, useState } from "react";
import {
  fetchPaidCount,
  fetchSchoolCount,
  fetchStudentsCount,
  fetchTripCount,
} from "../services";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function DriverPanel() {
  const [studentCount, setStudentCount] = useState([]);
  const [paidCount, setPaidCount] = useState([]);
  const [schoolCount, setschoolCount] = useState([]);
  const [tripCount, setTripCount] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    fetchStudentsCount()
      .then((res) => {
        setStudentCount(res.data[0]["COUNT(*)"]);
      })
      .catch((err) => console.log(err));

    fetchPaidCount()
      .then((res) => {
        setPaidCount(res.data[0]["COUNT(*)"]);
      })
      .catch((err) => console.log(err));

    fetchSchoolCount()
      .then((res) => {
        setschoolCount(res.data[0]["COUNT(*)"]);
      })
      .catch((err) => console.log(err));

    fetchTripCount()
      .then((res) => {
        setTripCount(res.data[0]["COUNT(*)"]);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="driverDashboard">
      <div className="top_content">
        <div className="tot_students" onClick={() => navigate("/students/filter")}>
          <div className="count" >
            {studentCount}
          </div>
          <div className="text">Student Count</div>
        </div>
        <div className="paid_students"  onClick={() => navigate("/driver/payment")}>
          <div className="count">
            {paidCount}
          </div>
          <div className="text">Paid Count</div>
        </div>
      </div>
      <div className="bottum_content">
        <div className="tot_trips">
          <div className="count">{tripCount}</div>
          <div className="text">Total Trips</div>
        </div>
        <div className="tot_school">
          <div className="count">{schoolCount}</div>
          <div className="text">School Count</div>
        </div>
      </div>
    </div>
  );
}

export default DriverPanel;
