import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Button, Input, Space, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { validateRequiredFields } from "../validation";
import { checkLogin } from "../services";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";

function DriverLogin() {
  const location = useLocation();
  const getDriverNic = location.state;
  const navigate = useNavigate();

  const [password, setPassword] = useState("");

  const [nicError, setNicError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [loginError, setLoginError] = useState("");
  const [processingLogin, setProcessingLogin] = useState(false);

  const [tokenCreated, setTokenCreated] = useState(false);

  const onLoginClick = async (e) => {
    e.preventDefault();

    // Check if the user is already logged in using cookies
    const authToken = Cookies.get("authToken");
    if (authToken) {
      setLoginError("You are already logged in");
      return;
    }

    const nicValidationResult = validateRequiredFields(getDriverNic);
    const passwordValidationResult = validateRequiredFields(password);

    if (!getDriverNic || !password) {
      setNicError(nicValidationResult);
      setPasswordError(passwordValidationResult);
    } else {
      setNicError("");
      setPasswordError("");

      await checkLogin({
        driverNic: getDriverNic,
        password: password,
      })
        .then((res) => {
          if (res.data.status === "fail") {
            setLoginError("NIC or Phone Number is Wrong.");
          } else if (res.data.status === "success") {
            const token = res.data.token;
            // Store the token in a cookie
            Cookies.set("authToken", token, { expires: 5 }); // 5 days

            setTokenCreated(true);
          } else if (res.data.status === "error") {
            setLoginError("Something went wrong. Try Again.");
          }
        })
        .catch((err) => {});
    }
    window.location.reload();
  };

  // img slider
  let slideIndex = 0;

  function showSlides() {
    const slides = document.getElementsByClassName("fade");

    if (slides.length === 0) {
      console.error("No slides found with class 'fade'");
      return;
    }

    let i;

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slideIndex++;

    if (slideIndex > slides.length) {
      slideIndex = 1;
    }

    slides[slideIndex - 1].style.display = "block";
    setTimeout(showSlides, 4000);
  }

  window.onload = function () {
    showSlides();
  };

  return (
    <div className="driverLogin_root">
      <div className="nav_Container">
        <div className="nav">
          <ArrowLeftOutlined onClick={() => navigate("/")} />
          <span>WELCOME</span>
          <QuestionCircleOutlined />
        </div>
      </div>
      <div className="sub_content">
        <div className="fade">
          <img src="https://safekids.creadea.lk/bannerlandingpage_1.png" />
        </div>
        <div className="fade">
          <img src="https://safekids.creadea.lk/safekidsbanner.png" />
        </div>
      </div>
      {processingLogin && (
        <div className="loadingIconContainer">
          <LoadingOutlined className="loadingIcon" spin />
        </div>
      )}
      <div className="content_container">
        <Space direction="vertical">
          {loginError && (
            <div style={{ color: "red", textAlign: "center" }}>
              {loginError}
            </div>
          )}
          <div
            style={{
              height: "60px",
              margin: "0px 0px 0px 0px",
            }}
          >
            <Input
              className={`form-input ${nicError ? "is-invalid" : ""}`}
              value={getDriverNic}
              placeholder="Driver NIC"
              disabled
            />
            {nicError && <div className="invalid-feedback">{nicError}</div>}
          </div>
          <div
            style={{
              height: "60px",
              margin: "0px 0px 0px 0px",
            }}
          >
            <Input.Password
              className={`form-input ${passwordError ? "is-invalid" : ""}`}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Driver password"
            />
            {passwordError && (
              <div className="invalid-feedback">{passwordError}</div>
            )}
          </div>
          <button type="primary" className="loginBtn" onClick={onLoginClick}>
            Login
          </button>
        </Space>
      </div>

      <div className="top_svg">
        <svg
          width="205"
          height="174"
          viewBox="0 0 205 174"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_12)">
            <rect
              x="92.387"
              y="159.066"
              width="229.41"
              height="336.177"
              rx="59"
              transform="rotate(-111.405 92.387 159.066)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_12"
              x="0.107971"
              y="-197.765"
              width="431.819"
              height="371.383"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="9" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_12"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_12"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="bottom_svg">
        <svg
          width="273"
          height="543"
          viewBox="0 0 273 543"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_160_2)">
            <rect
              x="-83.8308"
              y="18"
              width="360"
              height="608"
              rx="59"
              transform="rotate(26.3822 -83.8308 18)"
              fill="#63A8B8"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_160_2"
              x="-349.941"
              y="0.0591431"
              width="622.557"
              height="734.527"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="19" dy="-3" />
              <feGaussianBlur stdDeviation="17.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_160_2"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_160_2"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
}

export default DriverLogin;
