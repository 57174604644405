import {useState, useEffect} from "react";
import {checkDriverNIC, checkParentNIC} from "./services";

export function useDriverValidation(nic) {
    const [drivers, setDrivers] = useState("");
    const [isRegistered, setIsRegistered] = useState(false);

    useEffect(() => {
        if (nic) {
            checkDriverNIC(nic)
                .then((res) => {
                    setDrivers(res.data);
                })
                .catch((err) => console.log(err));
        }
    }, [nic]);

    useEffect(() => {
        if (drivers) {
            setIsRegistered(drivers.length >= 1);
        }
    }, [drivers]);

    return isRegistered;
}

export function useParentValidation(parentNic) {
    const [parents, setParents] = useState("");
    const [isParentRegistered, setIsParentRegistered] = useState(false);

    useEffect(() => {
        if (parentNic) {
            checkParentNIC(parentNic)
                .then((res) => {
                    setParents(res.data);
                })
                .catch((err) => console.log(err));
        }
    }, [parentNic]);

    useEffect(() => {
        if (parents) {
            setIsParentRegistered(parents.length >= 1);
        }
    }, [parents]);

    return isParentRegistered;
}

export function validateRequiredFields(name) {
    if (!name) {
        return "This Field is required";
    }
    return "";
}

export function validatePhone(phone) {
    if (!phone) {
        return "Phone number is required";
    } else if (!/^[0-9+]+$/.test(phone)) {
        return "Invalid phone number format";
    }
    return "";
}

export function validateNoneRequiredPhone(phone) {
    if (!/^[0-9+]+$/.test(phone)) {
        return "Invalid phone number format";
    }
    return "";
}

// export function validatePassword(password) {
//   if (!password) {
//     return "Password is required";
//   } else if (password.length < 6) {
//     return "Password must be at least 6 characters long";
//   }
//   return "";
// }

// export function validateEmail(email) {
//   if (!email) {
//     return "Email is required";
//   } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
//     return "Invalid email format";
//   }
//   return "";
// }
