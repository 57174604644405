import axios from "axios";

//const API_URL = "https://creadeaattendacncebackend.onrender.com/api";
const API_URL = "https://backend.creadeastore.store";

//sending massege
export const sendSMS = (message, phoneNumber) => {
  const data = JSON.stringify({
    message: message,
    phoneNumber: phoneNumber,
  });

  const config = {
    method: "post",
    url: "https://api.smshub.lk/api/v1/send/single",
    headers: {
      Authorization:
        "ofvpVx2nsCcVJDGgczMMFnpJUsmyf7jXNpg8tpbd1qygCAViJJVQrDEaHcaq1ZZzV6a8crX4Q3ro9dumSjTFneU2vHIdWHVoZZ14ae5YKIwKcBGo9tjCiQ0s0VfQ44znxICjdAtohgcBDINvcyR7MpwIduL5EkZaYWIz6F2dX9b7qgf2r8BUZUeFfdq7cF5LS4CnzbzjR0IToCNNTaaLxCVR4IvjDgEHI4RjG9Zw8oqZLnEG0FpNUZ1UCB2u66Ma046BVOatW8qSYHweSZCkj4e0RtOIqzSzhWRr68saJwye2FrcplzYo4xJ5qq1sVLNlqJn7QY62nwXuuP78SyQADH0BZcC8aKs3yoRBnjO1KRxysd2thdgiJiJUJzq1KLQYxJ3eIB0WoI4KeBdObqA4jpEYrdMHnxNoe8ZRDhVUFZoeXAbs896IfazBbZI5nbmxVCgv8DMpd8UoTbeZb1kx9Z06e6hNtMtlPG2kOp9djaa9lvMqJtUuECnTNKrPU7Gi4MrAMsvTv3O3IpzN0TGKZtywtEzgkxjy0AuvSGTel5icQpub5gBqGBQutRNldQzawQIUEiBmFppWBkM5PcE65wX6uf4QkcrRL7Hz5NX3SHcihUypbtnEH2Lwwf3nwCkBrKBc5fEKuRNQCpCRm2icFi95QbXorYjL9zPUOLKRbK1xP3Lbd2Cj0PkxKgmrHfUqfugbR9MUoKF8zl8o8HHh5BaYI2zIcWKn9AMg3hJjuyTudz2hsP5ngI4UucyiqMjxc5TqTM8Galll7uQvAZL7ZHymPnGdon3QNUGEqPoWTNAmpKZcZmWpEXVPi1s1R5z2R1EWP0BQ2ut7xKtGTixsOnJ61kFFrD2J0psca8Bp2JSzh2D9UlG6IFafva1gCMtyFsLwavu9G1RHcqp7NDe0M1P49r0yiyWpPVWV1gnayQVaMt7rni7Ej43qLF0H32a6qEbuQHrT1ohlKsVFGlIAgTAMEY7zPlcWsDCW9laBREmS9MpEi5ADmlXZQJAjUyt",
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
};

// Create
export const createDriver = (driverData) => {
  return axios.post(`${API_URL}/create/driver`, driverData);
};
export const createParent = (parentData) => {
  return axios.post(`${API_URL}/create/parent`, parentData);
};
export const createStudent = (studentData) => {
  return axios.post(`${API_URL}/create/student`, studentData);
};
export const createAttendance = (attendanceData) => {
  return axios.post(`${API_URL}/create/attendance`, attendanceData);
};
export const createPayment = (paymentData) => {
  return axios.post(`${API_URL}/create/payment`, paymentData);
};
export const createPaymentSatus = (paymentStatusData) => {
  return axios.post(`${API_URL}/create/payment/status`, paymentStatusData);
};
export const createSchool = (schoolData) => {
  return axios.post(`${API_URL}/create/school`, schoolData);
};
export const createTrip = (TripData) => {
  return axios.post(`${API_URL}/create/Trip`, TripData);
};
export const checkLogin = async (credentials) => {
  return await axios.post(`${API_URL}/login`, credentials);
};
export const createPending = async (month) => {
  return await axios.post(`${API_URL}/create/Pending`, month);
};

//Read
export const checkDriverNIC = (nic) => {
  return axios.get(`${API_URL}/checkdriver/${nic}`);
};
export const checkParentNIC = (parentNic) => {
  return axios.get(`${API_URL}/checkparent/${parentNic}`);
};
export const checkParentName = (parentName) => {
  return axios.get(`${API_URL}/checkparentName/${parentName}`);
};
export const checkStudentId = (studentId) => {
  return axios.get(`${API_URL}/checkStudent/${studentId}`);
};
export const checkSchoolId = (schoolId) => {
  return axios.get(`${API_URL}/checkSchoolId/${schoolId}`); // check student by school id
};
export const checkLastStudent = () => {
  return axios.get(`${API_URL}/checkLastStudent`);
};
export const checkPayment = (studentId) => {
  return axios.get(`${API_URL}/checkPayment/${studentId}`);
};
export const checkPaymentStatus = (paymentValue) => {
  return axios.get(`${API_URL}/checkPaymentstatus/${paymentValue}`);
};
export const checkSchool = (schoolName) => {
  return axios.get(`${API_URL}/checkSchool/${schoolName}`);
};
export const fetchUserTypes = () => {
  return axios.get(`${API_URL}/usertypes`);
};
export const fetchParents = () => {
  return axios.get(`${API_URL}/getParents`);
};
export const fetchStudents = () => {
  return axios.get(`${API_URL}/students`);
};
export const fetchStudentsCount = () => {
  return axios.get(`${API_URL}/studentCount`);
};
export const fetchPaidCount = () => {
  return axios.get(`${API_URL}/paidCount`);
};
export const fetchSchoolCount = () => {
  return axios.get(`${API_URL}/schoolCount`);
};
export const fetchTripCount = () => {
  return axios.get(`${API_URL}/tripCount`);
};
export const filterSchool = (schoolId) => {
  return axios.get(`${API_URL}/filterSchool/${schoolId}`);
};
export const filterStudent = (searchValue) => {
  return axios.get(`${API_URL}/filterStudent/${searchValue}`);
};
export const filterAllStudent = () => {
  return axios.get(`${API_URL}/filterAllStudent`);
};
export const filterOneStudent = (stu_id) => {
  return axios.get(`${API_URL}/filterOneStudent/${stu_id}`);
};
export const checkLastTrip = () => {
  return axios.get(`${API_URL}/checkLastTrip`);
};
export const filterPaidStudent = (status) => {
  return axios.get(`${API_URL}/filterPaidStudent/${status}`);
};
export const lastMonthUnPaidStudent = () => {
  return axios.get(`${API_URL}/lastMonthUnPaidStudent`);
};
export const checkLastMonthPaid = (stu_id) => {
  return axios.get(`${API_URL}/checkLastMonthPaid/${stu_id}`);
};
export const fetchUnDropedStudent = (trip_id) => {
  return axios.get(`${API_URL}/fetchUnDropedStudent/${trip_id}`);
};

export const fetchAllAttendance = (trip_id) => {
  return axios.get(`${API_URL}/fetchAllAttendance/${trip_id}`);
};
// Update
export const updateStudent = (studentId, studentData) => {
  return axios.put(`${API_URL}/update/${studentId}`, studentData);
};
export const updatePaymentstatus = (studentId, status) => {
  return axios.put(`${API_URL}/update/PaymentStatus/${studentId}`, status);
};
export const updateDropSchool = (schoolName, drop) => {
  return axios.put(`${API_URL}/update/DropSchool/${schoolName}`, drop);
};
export const updateDropStudent = (stu_id, drop) => {
  return axios.put(`${API_URL}/update/DropStudent/${stu_id}`, drop);
};
export const resetPaymentStatus = (month) => {
  return axios.put(`${API_URL}/reset/PaymentStatus`, month);
};

// Delete
export const deleteStudent = (studentId) => {
  return axios.delete(`${API_URL}/delete/${studentId}`);
};
export const deletePendingStudent = (studentId) => {
  return axios.delete(`${API_URL}/delete/pending/${studentId}`);
};
